import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { Styled } from '../welcomePage/desktop/styles';

const SOCIAL_MEDIA_LINKS = {
  FACEBOOK: 'https://www.facebook.com/LooKey.RE',
  INSTAGRAM: 'https://www.instagram.com/lookey.re',
  LINKEDIN: 'https://www.linkedin.com/company/lookey',
} as const;

const footerRightsTxt = `כל הזכויות שמורות ללוקי ${new Date().getFullYear()}`;

const useStyles = makeStyles(Styled);

interface IExternalLink {
  children: ReactNode;
  href: string;
}

const ExternalLink = ({ children, href }: IExternalLink) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ all: 'unset', cursor: 'pointer' }}
    >
      {children}
    </a>
  );
};

const Footer = ({
  onBuyerLogin,
  onSellerLogin,
}: {
  onBuyerLogin: () => void;
  onSellerLogin: () => void;
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={4}
      direction="row-reverse"
      className={classes.footer}
      style={{
        width: '100%',
        paddingTop: 50,
        paddingRight: 50,
        paddingLeft: 20,
        textAlign: 'right',
        paddingBottom: '20px',
      }}
    >
      <Grid
        item
        direction="column"
        container
        spacing={2}
        md={2}
        style={{ paddingBottom: 100 }}
      >
        <Grid item className={classes.footerTitle}>
          החברה
        </Grid>
        <Grid item className={classes.footerSubTitle}>
          <ExternalLink href={`${window.location.origin}/public/about`}>
            אודות
          </ExternalLink>
        </Grid>
        <Grid item className={classes.footerSubTitle}>
          <ExternalLink href={`${window.location.origin}/public/policy`}>
            תקנון ומדיניות
          </ExternalLink>
        </Grid>
        <Grid item className={classes.footerSubTitle}>
          <ExternalLink href={`${window.location.origin}/public/contact-us`}>
            צור קשר
          </ExternalLink>
        </Grid>
      </Grid>
      <Grid item direction="column" container spacing={2} md={2}>
        <Grid item className={classes.footerTitle}>
          מוצרים
        </Grid>
        <Grid item className={classes.footerSubTitle} onClick={onBuyerLogin}>
          חיפוש דירה{' '}
        </Grid>
        <Grid item className={classes.footerSubTitle} onClick={onSellerLogin}>
          מפרסם דירה{' '}
        </Grid>
        <Grid item className={classes.footerSubTitle} onClick={onSellerLogin}>
          מחשבון ביקוש{' '}
        </Grid>
      </Grid>
      <Grid container item md={8} spacing={2} alignItems="flex-end">
        <Grid item>
          <ExternalLink href={SOCIAL_MEDIA_LINKS.LINKEDIN}>
            <img
              src="https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/linkedin_icon.png"
              className={classes.icon}
            />
          </ExternalLink>
        </Grid>
        <Grid item>
          <ExternalLink href={SOCIAL_MEDIA_LINKS.FACEBOOK}>
            <img
              src="https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/facebook_icon.png"
              className={classes.icon}
            />
          </ExternalLink>
        </Grid>
        <Grid item>
          <ExternalLink href={SOCIAL_MEDIA_LINKS.INSTAGRAM}>
            <img
              src="https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/instagram_icon.png"
              className={classes.icon}
            />
          </ExternalLink>
        </Grid>
      </Grid>
      <Grid
        item
        container
        style={{ paddingBottom: '30px' }}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {footerRightsTxt}
      </Grid>
    </Grid>
  );
};

export default Footer;
