import React, { useContext } from 'react'
import firebase from 'firebase/app'

interface Props {
    firebaseApp: firebase.app.App | undefined,
    uiConfig: any
  }

export const AuthProviderContext = React.createContext<Props>({} as Props)

export const useAuthProviderAction = () => {
  return useContext(AuthProviderContext)
}
