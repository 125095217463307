import React, { useEffect, useState } from 'react';
import axios from 'axios';

import firebase from 'firebase';

import segment from '../services/segment';
import 'firebase/auth';
import { getRedirectToUrl } from '../utils';
import PageMessage from '../PageMessage';
import { AuthProviderContext } from './context';

import '../App.css';

type FirebaseConfigObj = {
  apiKey: string | undefined;
  authDomain: string | undefined;
  databaseURL: string | undefined;
  projectId: string | undefined;
  storageBucket: string | undefined;
  messagingSenderId: string | undefined;
  appId: string | undefined;
  measurementId: string | undefined;
};

export const getConfigObj = (): FirebaseConfigObj => {
  return {
    apiKey: process.env.FIREBASE_APIKEY,
    authDomain:
      process.env.NODE_ENV === 'production'
        ? 'lookey.co.il'
        : 'int.lookey.co.il',
    databaseURL: process.env.FIREBASE_DATABASEURL,
    projectId: process.env.FIREBASE_PROJECTID,
    storageBucket: process.env.FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
    appId: process.env.FIREBASE_APPID,
    measurementId: process.env.FIREBASE_MEASUREMENTID,
  };
};

const firebaseConfig = getConfigObj();
if (!firebaseConfig) {
  throw new Error('missing firebase config');
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

const checkDisposable = (email: string) => {
  const emailProvider = email.split('@')[1];

  return new Promise((resolve, reject) => {
    axios
      .get(`https://open.kickbox.com/v1/disposable/${emailProvider}`)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const getProviders = () => {
  return [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    },
    // {
    //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //   iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg'
    // }
  ];
};

const uiConfig = {
  authDomain:
    process.env.NODE_ENV === 'production'
      ? 'api.lookey.co.il'
      : 'int.api.lookey.co.il',
  signInOptions: getProviders(),
  credentialHelper: 'none',
  callbacks: {
    signInSuccessWithAuthResult: ({ user }: { user: any }) => {
      if (user) {
        window.location.reload();
      }

      const appUrl = getRedirectToUrl('redirectUrl');

      window.location.replace(appUrl);
    },
  },
};

let unregisterAuthObserver = null;

interface Props {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: Props) => {
  const [showPageMessage, setShowPageMessage] = useState(
    !!localStorage.getItem('notValidEmailProvider')
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (localStorage.getItem('notValidEmailProvider')) {
      localStorage.removeItem('notValidEmailProvider');
      return null;
    } else {
      unregisterAuthObserver = firebaseApp.auth().onAuthStateChanged(user => {
        if (user) {
          // eslint-disable-next-line consistent-return
          checkDisposable(user.email)
            .then((res: any) => {
              if (res.data.disposable) {
                localStorage.setItem('notValidEmailProvider', String(true));

                user.delete().then(() => {
                  window.location.reload();
                });
              } else {
                const appUrl = getRedirectToUrl('redirectUrl');

                return window.location.replace(appUrl);
              }
            })
            .catch(() => {
              const appUrl = getRedirectToUrl('redirectUrl');

              window.location.replace(appUrl);
            });
        } else {
          segment.track('Welcome Page visit');
        }
      });

      return unregisterAuthObserver;
    }
  }, []);

  if (showPageMessage) {
    return (
      <PageMessage
        textsArr={['אימייל לא תקין', 'אנא הכנס אימייל אחר']}
        onActionClick={() => setShowPageMessage(false)}
        actionText="חזרה"
      />
    );
  }

  return (
    <AuthProviderContext.Provider
      value={{
        firebaseApp,
        uiConfig,
      }}
    >
      {children}
    </AuthProviderContext.Provider>
  );
};

export default AuthProvider;
