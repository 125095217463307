import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Grid, Container } from '@material-ui/core';
import { isEmpty } from 'lodash';
import useFetch, { CachePolicies } from 'use-http';
import { useBreakpoint } from '../utils/breakpoints';
import SubjectIcon from '@material-ui/icons/Subject';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import Form from '../Form';
import ResultsMessage from '../ResultsMessage';
import useContactUsFormValidation from '../hooks/useContactUsFormValidation';
import LabelWrapper from '../LabelWrapper';
import Typography from '../Typography';
import { Styled } from '../welcomePage/desktop/styles';

export interface InitProps {
  email: string;
  fullName: string;
  textComment: string;
}

export interface Props {
  onSubmit?: (data?: InitProps) => Promise<any>;
}

const INIT_FORM: InitProps = {
  email: '',
  fullName: '',
  textComment: '',
};

const useStyles = makeStyles(Styled);

const ContactUsForm: React.FC<Props> = ({ onSubmit = () => {} }) => {
  const {
    post,
    loading,
    response,
  } = useFetch(`${process.env.HOST_URL}/api/feedbacks/contactUs`, {
    cachePolicy: CachePolicies.NO_CACHE,
  });
  const classes = useStyles();
  const isCompactLayout = useBreakpoint(['small', 'medium']);
  const [validateForm] = useContactUsFormValidation();
  const [initData, setInitData] = useState(INIT_FORM);

  const handleOnSubmitForm = (values: InitProps | undefined) => {
    return post({
      email: values?.email,
      name: values?.fullName,
      userComment: values?.textComment,
    });
  };

  const handleOnSubmit = (data: InitProps) => {
    const vlidation = validateForm(data);

    if (!isEmpty(vlidation)) {
      return vlidation;
    }

    setInitData(data);
    onSubmit(data);

    return handleOnSubmitForm(data);
  };

  if (response.ok && response.status === 200) {
    return (
      <Container>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={2}
          style={{ marginTop: 80 }}
        >
          <Grid item>
            <ResultsMessage
              title="תודה שיצרת איתנו קשר, אם יש צורך נחזור אליך בהקדם"
              icon={
                <ThumbUpIcon
                  fontSize="large"
                  style={{
                    height: 150,
                    width: 'auto',
                    marginTop: 40,
                    color: '#E087B1',
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Form
      initialValues={initData}
      onSubmit={handleOnSubmit}
      validate={validateForm}
      style={
        isCompactLayout
          ? { width: '100%' }
          : { maxWidth: '500px', minWidth: '500px' }
      }
    >
      <Grid
        container
        alignItems="stretch"
        spacing={4}
        direction="column"
        justifyContent="center"
        style={
          isCompactLayout
            ? { width: '100%', margin: 0 }
            : { maxWidth: '500px', minWidth: '500px' }
        }
      >
        <Grid item container justifyContent="center">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography style={{ fontSize: '24px', color: '#000' }}>
                יש לכם עוד שאלות?
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontSize: '22px', color: '#000' }}>
                דברו איתנו
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container wrap="nowrap">
          <LabelWrapper
            label="אימייל"
            icon={<MailOutlineIcon fontSize="small" />}
            direction="column"
          >
            <Form.Input name="email" />
          </LabelWrapper>
        </Grid>
        <Grid item container wrap="nowrap">
          <LabelWrapper
            label="שם מלא"
            icon={<PersonOutlineIcon fontSize="small" />}
            direction="column"
          >
            <Form.Input name="fullName" />
          </LabelWrapper>
        </Grid>
        <Grid item container wrap="nowrap">
          <LabelWrapper
            label="טקסט חופשי"
            icon={<SubjectIcon fontSize="small" />}
            direction="column"
          >
            <Form.Input
              style={{ border: '1px solid #DFDFDF' }}
              rows={4}
              rowsMax={10}
              multiline
              name="textComment"
            />
          </LabelWrapper>
        </Grid>
        <Grid justify="center" item container wrap="nowrap">
          <Form.SubmitButton
            color="primary"
            variant="contained"
            className={classes.textLinkForm}
          >
            <Typography color="inherit">שלח</Typography>
          </Form.SubmitButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ContactUsForm;
