import { createStyles } from '@material-ui/core/styles'


export const StyledButton = createStyles({
  containedPrimary: {
    color: '#fff',
    '&:hover': {
      backgroundColor: '#006064',
      color: '#fff'
    }
  },
  outlinedPrimary: {
    '&:hover': {
      backgroundColor: 'rgba(116, 198, 190, 0.23)'
    }
  },
  containedSecondary: {
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#F65C8C'
    }
  },
  outlinedSecondary: {
    '&:hover': {
      backgroundColor: 'rgba(224, 135, 177, 0.23)'
    }
  }
})
