import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(createStyles({
  root: {
    fontFamily: 'Heebo',
    height: '15px',
    background: '#FF8A80',
    borderRadius: '50px',
    color: '#FFFFFF',
    fontSize: '11px',
    padding: '2px'
  },
  textGridItem: {
    textAlign: 'center',
    width: '70%'
  },
  linkAction: {
    fontFamily: 'Heebo',
    fontWeight: 'bold',
    fontSize: '17px',
    textDecorationLine: 'underline',
    color: '#4BB5AF',
    alignSelf: 'center',
    cursor: 'pointer'
  }
}));

const PageMessage = ({
  textsArr, onActionClick, icon, actionText, spacing
}:{
  textsArr:string[], onActionClick:()=>void, icon?:any, actionText:string, spacing?:any
}) => {
  const classes = useStyles();

  return (
    <Grid
      direction="column"
      alignItems="stretch"
      justify="center"
      spacing={ spacing }
      container >
      <Grid item container justify="center">
        {icon}
      </Grid>
      <Grid item container justify="center">
        <Grid
          direction="column"
          alignItems="center"
          justify="center"
          spacing={ 2 }
          container >
          {textsArr.map((text) => <Grid item container justify="center" className={ classes.textGridItem }>{text}</Grid>)}
        </Grid>
      </Grid>
      <Grid item container justify="center">
        {!!(actionText) && (
          <div className={ classes.linkAction } onClick={ onActionClick }>
            {actionText}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

PageMessage.defaultProps = {
  textsArr: [],
  onActionClick: () => {},
  actionText: '',
  spacing: 6,
  icon: <div />
};

PageMessage.propTypes = {
  textsArr: PropTypes.array,
  onActionClick: PropTypes.func,
  icon: PropTypes.node,
  actionText: PropTypes.string,
  spacing: PropTypes.number
};

export default PageMessage;
