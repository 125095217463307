import React from 'react';
import styled from 'styled-components';
import { BannerType } from '~/types/banner';
import { useBreakpoint } from '../utils/breakpoints';

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const MobileBannerImage = styled.img`
  width: 345px;
  height: 391px;
`;

const Banner = ({
  type,
  onClick,
}: {
  type: BannerType;
  onClick: () => void;
}) => {
  const isCompactLayout = useBreakpoint(['small', 'medium']);

  const device = isCompactLayout ? 'mobile' : 'desktop';
  const imageByTypeMap = {
    mortgage: `banner_mashkanta_${device}`,
    'for-sale': `banner_forsale_${device}`,
    'rent-check': `banner_rentcheck_${device}`,
  };

  const src = `https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/${imageByTypeMap[type]}.png`;

  const handleOpen = () => {
    onClick();
  };

  return (
    <BannerContainer onClick={handleOpen}>
      {isCompactLayout ? <MobileBannerImage src={src} /> : <img src={src} />}
    </BannerContainer>
  );
};

export default Banner;
