import { useCallback, useState } from 'react';

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const hideModal = useCallback(() => setIsOpen(false), [setIsOpen]);

  const showModal = useCallback(() => setIsOpen(true), [setIsOpen]);

  const toggleOpen = useCallback(() => setIsOpen(prev => !prev), [setIsOpen]);

  return {
    showModal,
    hideModal,
    isOpen,
    toggleOpen,
  };
};

export { useModal };
