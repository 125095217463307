import { createStyles } from '@material-ui/core/styles'

import { screens } from '../utils/breakpoints'
// import palette from '~/ThemeProvider/config/palette'

export const Styled = createStyles({
  root: {
    width: '450px',
    [screens('small', 'medium')]: {
      width: 'auto'
    }
  }
})
