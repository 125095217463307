import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { StyledMessageGridItem } from './styles'
import Typography from '../Typography'

interface Props {
  title: string | React.ReactNode
  subTitle?: string
  icon: React.ReactNode
  actionButton?: React.ReactNode
}

const useStyles = makeStyles(StyledMessageGridItem)

const ResultsMessage: React.FC<Props> = ({ title, subTitle, icon, actionButton = null }) => {
  const classes = useStyles()

  return (
    <Grid container alignItems='center' direction='column' spacing={2}>
      <Grid item>
        {icon}
      </Grid>
      <Grid item>
        <Grid container alignItems='center' direction='column' spacing={2}>
          <Grid item className={classes.root}>
            <Typography size='xlarge' weight='bold'>
              {title}
            </Typography>
          </Grid>
          {subTitle && (
            <Grid item className={classes.root}>
              <Typography size='medium'>{subTitle}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid>
        {actionButton}
      </Grid>
    </Grid>
  )
}

export default ResultsMessage
