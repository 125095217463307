
const SEGMENT_KEY = process.env.SEGMENT_KEY;

analytics.load(SEGMENT_KEY);

export default {
  _defaultPayload: undefined,
  initialize() {
    const searchParam = document.location && new URLSearchParams(document.location.search);
    const isFbReferrer = (searchParam && searchParam.get('fbclid')) || (document.referrer && document.referrer.includes('facebook.com'));

    // analytics.page();
    this._defaultPayload = {
      target: 'Welcome Page',
      FacebookReferrer: isFbReferrer
    };
  },
  track(eventName:string, eventData:object|undefined = {}) {
    const payload = {
      ...this._defaultPayload,
      ...eventData
    };

    analytics.track(eventName, payload);

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-line no-console
      console.log(`Sent event to Segment.com: "${eventName}" with payload: ${JSON.stringify(payload)}`);
    }
  }
};
