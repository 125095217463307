import { createStyles } from '@material-ui/core/styles'


export default createStyles({
  root: {
    marginTop: '0.25em',
    color: 'red'
  },
  error: {
    color: 'red',
    fontSize: '0.7em',
    cursor: 'default'
  }
})
