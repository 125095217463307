import React, { useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { Drawer } from '@material-ui/core';

import LoginModal from '../../LoginModal';
import BannerModal from '../../BannerModal';
import { useModal } from '../../utils/useModal';
import { screens } from '../../utils/breakpoints';
import { useBreakpoint } from '../../utils/breakpoints';
import ContactUsForm from '../../ContactUsForm';
import Footer from '../../Footer';
import { Styled } from './styles';
import { BannerType } from '~/types/banner';
import Banner from '../../Banner';

const WelcomeContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
  padding: 48px 32px 0;
  width: 100%;
`;

const Divider = styled.div`
  height: 0.5px;
  width: 100%;
  background-color: #949090;
`;

const SectionLink = styled.a`
  color: #4bb5af;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ,
  ${screens('small', 'medium')} {
    font-size: 16px;
    text-align: center;
  }
`;

const DescriptionSectionTitle = styled.div`
  color: #000;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  padding: 16px;
  line-height: normal;
  ${screens('small', 'medium')} {
    font-size: 16px;
    text-align: center;
  }
`;

const LogoImgNew = styled.img`
  width: 140px;
  height: auto;
`;

const DescriptionSectionImgMobile = styled.img`
  width: 300px;
  height: 300px;
`;

const TopBarContainer = styled.div`
  width: 100%;
  height: 70px;
`;

const LOGO_SRC =
  'https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/V4_app_logo.png';
const MAIN_IMAGE =
  'https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/welcome_main_desktop_v4.png';

const useStyles = makeStyles(Styled);

const TopBar = ({ openLogin }: { openLogin: () => void }) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isCompactLayout = useBreakpoint(['small', 'medium']);

  const toggleMenu = () => setIsMenuOpen(prevOpen => !prevOpen);

  const onClickContactUs = () => {
    window
      .open(`${window.location.origin}/public/contact-us`, '_blank')
      .focus();
  };
  const onClickAbout = () => {
    window.open(`${window.location.origin}/public/about`, '_blank').focus();
  };
  const onClickPolicy = () => {
    window.open(`${window.location.origin}/public/policy`, '_blank').focus();
  };

  const Links = () => (
    <>
      <Grid item className={classes.topBar} onClick={openLogin}>
        כניסה
      </Grid>
      <Grid item className={classes.topBar} onClick={onClickAbout}>
        אודות
      </Grid>
      <Grid item className={classes.topBar} onClick={onClickPolicy}>
        תקנון ומדיניות
      </Grid>
      <Grid item className={classes.topBar} onClick={onClickContactUs}>
        צור קשר
      </Grid>
    </>
  );

  return (
    <TopBarContainer>
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          height: '100%',
          paddingLeft: isCompactLayout ? '10px' : '60px',
          paddingRight: isCompactLayout ? '10px' : '60px',
        }}
      >
        <Grid item>
          <LogoImgNew src={LOGO_SRC} />
        </Grid>
        {!isCompactLayout ? (
          <Grid item style={{ height: '100%' }} alignItems="center">
            <Grid
              container
              direction="row-reverse"
              alignItems="center"
              style={{ height: '100%' }}
            >
              <Links />
            </Grid>
          </Grid>
        ) : (
          <Grid item onClick={toggleMenu} style={{ cursor: 'pointer' }}>
            {!isMenuOpen ? (
              <Grid item>
                <MenuIcon
                  fontSize="large"
                  style={{
                    color: 'black',
                  }}
                />
              </Grid>
            ) : (
              <Drawer
                anchor="right"
                open={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="flex-end"
                  spacing={2}
                  style={{ width: '300px', paddingTop: '20px' }}
                >
                  <Grid item>
                    <CloseIcon
                      fontSize="large"
                      style={{
                        color: 'black',
                        marginRight: '10px',
                        paddingBottom: '30px',
                      }}
                    />
                  </Grid>
                  <Links />
                </Grid>
              </Drawer>
            )}
          </Grid>
        )}
      </Grid>
    </TopBarContainer>
  );
};

const TitleSection = ({
  onBuyerLogin,
  onSellerLogin,
}: {
  onBuyerLogin: () => void;
  onSellerLogin: () => void;
}) => {
  const classes = useStyles();
  const isCompactLayout = useBreakpoint(['small', 'medium']);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid md={6} item>
        <img src={MAIN_IMAGE} width={isCompactLayout && 300} />
      </Grid>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems={isCompactLayout ? 'center' : 'flex-start'}
        style={{ height: '100%' }}
        md={6}
      >
        <Grid item className={classes.titleSectionTitle}>
          מייצרים התאמות טכנולוגיות בין מחפשי דירות למפרסמים
        </Grid>
        <Grid item className={classes.titleSectionSubtitle}>
          המערכת של לוקי תעזור לכם למצוא את ההתאמה המושלמת במינימום זמן ומאמץ
        </Grid>
        <Grid
          container
          spacing={isCompactLayout ? 1 : 4}
          direction={isCompactLayout ? 'column' : 'row'}
          alignItems="center"
          style={{
            width: '100%',
            paddingTop: '16px',
            paddingRight: isCompactLayout ? '0px' : '10px',
          }}
        >
          <Grid item>
            <Grid onClick={onBuyerLogin} item className={classes.textLink}>
              אני מחפש דירה
            </Grid>
          </Grid>
          <Grid item>
            <Grid onClick={onSellerLogin} item className={classes.textLink}>
              אני מפרסם דירה
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface DescriptionItemProps {
  greenTitle?: boolean;
  title: string;
  description: string;
}

const DescriptionItem = ({
  greenTitle,
  title,
  description,
}: DescriptionItemProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justifyContent="space-between"
      style={{ maxWidth: '400px' }}
    >
      <Grid
        item
        className={
          greenTitle
            ? classes.DescriptionItemTitleGreen
            : classes.DescriptionItemTitle
        }
      >
        {title}
      </Grid>
      <Grid item className={classes.DescriptionItemText}>
        {description}
      </Grid>
    </Grid>
  );
};

interface DescriptionSectionProps {
  image: string;
  reverseDirection?: boolean;
  primaryItem: React.ReactNode;
  sectionTitle?: React.ReactNode;
  onSellerLogin?: () => void;
}

const DescriptionSection = ({
  sectionTitle,
  image,
  primaryItem,
  reverseDirection,
  onSellerLogin,
}: DescriptionSectionProps) => {
  const isCompactLayout = useBreakpoint(['small', 'medium']);

  const getDirection = () => {
    if (isCompactLayout) {
      return 'column';
    }

    return reverseDirection ? 'row-reverse' : 'row';
  };

  return (
    <>
      {sectionTitle && (
        <DescriptionSectionTitle>{sectionTitle}</DescriptionSectionTitle>
      )}
      <Grid
        container
        // spacing={2}
        direction={getDirection()}
        wrap="nowrap"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item alignItems="center" direction="column">
          {isCompactLayout ? (
            <DescriptionSectionImgMobile src={image} />
          ) : (
            <img src={image} />
          )}
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>{primaryItem}</Grid>
            {onSellerLogin && (
              <Grid
                item
                style={{ textAlign: isCompactLayout ? 'center' : 'right' }}
              >
                <SectionLink onClick={onSellerLogin}>
                  {'גלו מה רמת הביקוש לדירה שלכם >'}
                </SectionLink>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
const addRedirectToUrl = (path: string) => {
  const baseUrl = window.location.protocol + '//' + window.location.host;

  if (history.pushState) {
    var newurl =
      baseUrl +
      window.location.pathname +
      `?redirectUrl=${encodeURIComponent(`${baseUrl}/${path}`)}`;

    window.history.pushState({ path: newurl }, '', newurl);
  }
};

const WelcomePage = () => {
  const [bannerType, setBannerType] = useState<BannerType>();
  const classes = useStyles();
  const { showModal, hideModal, isOpen } = useModal();
  const {
    showModal: showBannerModal,
    hideModal: hideBannerModal,
    isOpen: isBannerModalOpen,
  } = useModal();

  const onSellerLogin = () => {
    addRedirectToUrl('seller');
    showModal();
  };

  const onBuyerLogin = () => {
    addRedirectToUrl('buyer');
    showModal();
  };

  const onBannerModalOpen = (type: BannerType) => {
    setBannerType(type);
    showBannerModal();
  };

  return (
    <>
      <TopBar openLogin={showModal} />
      <WelcomeContainerMobile>
        <Grid
          container
          spacing={4}
          direction="column"
          style={{ width: '100%', paddingLeft: 25, paddingRight: 25 }}
        >
          <Grid item>
            <TitleSection
              onBuyerLogin={onBuyerLogin}
              onSellerLogin={onSellerLogin}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Grid
              container
              spacing={5}
              direction="column"
              style={{ width: '100%', margin: 0 }}
            >
              <Grid item style={{ width: '100%' }}>
                <DescriptionSection
                  reverseDirection
                  sectionTitle="די לרדוף אחרי דירות"
                  image="https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/searchers_top_pink.png"
                  primaryItem={
                    <DescriptionItem
                      title="מייצרים פרופיל חדש"
                      description="מייצרים פעם אחת פרופיל חיפוש שכולל בדיוק מה חשוב לכם שיהיה בדירה ועבור מי הדירה שמחפשים והמערכת של לוקי תמצא את המפרסמים המתאימים."
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Banner
                  type="for-sale"
                  onClick={() => onBannerModalOpen('for-sale')}
                />
                {/* <Divider /> */}
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <DescriptionSection
                  image="https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/searchers_middle_pink.png"
                  primaryItem={
                    <DescriptionItem
                      title="התראות לנייד על התאמות חדשות"
                      description="המפרסמים צופים בפרופיל החיפוש שיצרתם ושולחים אליכם את המודעה שלהם. אתם צופים בפרטים ומחליטים אם ליצור קשר ולהתקדם."
                    />
                  }
                />
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <DescriptionSection
                  reverseDirection
                  image="https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/searchers_buttom_pink.png"
                  primaryItem={
                    <DescriptionItem
                      title="פרטי התקשורת שלכם לא חשופים"
                      description="שמפרסם דירה שולח לכם מודעה אתם תקבלו התראה מאיתנו, אנחנו לא חושפים את פרטי התקשורת שלכם."
                    />
                  }
                />
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                }}
              >
                <Grid onClick={onBuyerLogin} item className={classes.textLink}>
                  אני מחפש דירה
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Banner
              type="rent-check"
              onClick={() => onBannerModalOpen('rent-check')}
            />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Grid
              container
              spacing={4}
              direction="column"
              style={{ width: '100%', margin: 0 }}
            >
              <Grid item style={{ width: '100%' }}>
                <DescriptionSection
                  sectionTitle="מפרסמים רק למחפשים רלוונטים"
                  image="https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/owner_top_pink.png"
                  primaryItem={
                    <DescriptionItem
                      title="מחשבון ביקוש"
                      description="לא בטוחים כמה לבקש על הדירה? אנחנו נעזור לכם. לפני שתפרסמו את הדירה, מחשבון הביקוש שלנו יציג לכם כמה מחפשים מתאימים יש בזמן אמת בהתאם לכל מחיר שתגדירו."
                    />
                  }
                  onSellerLogin={onSellerLogin}
                />
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <DescriptionSection
                  reverseDirection
                  image="https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/owner_middle_pink.png"
                  primaryItem={
                    <DescriptionItem
                      title="מפרסמים רק למחפשים מתאימים"
                      description="במקום לחשוף את פרטי הדירה והטלפון שלכם בלוח בפומבי ולקבל טלפונים לא רלוונטים, בלוקי אתם חושפים את המודעה למחפשים הכי רלוונטים שמתאימים לכם"
                    />
                  }
                />
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <DescriptionSection
                  image="https://storage.googleapis.com/hoome-imgs/app-assets/v3/images/owner_bottom_pink.png"
                  primaryItem={
                    <DescriptionItem
                      title="מקבלים רק פניות רלוונטיות"
                      description="המחפשים המתאימים שתחליטו לחשוף להם את המודעה יקבלו התראה מאיתנו עם פרטי המודעה ויוכלו לפנות אליכם ישירות בוואטסאפ."
                    />
                  }
                />
              </Grid>
              <Grid item style={{ display: 'flex', alignSelf: 'center' }}>
                <Grid onClick={onSellerLogin} item className={classes.textLink}>
                  אני מפרסם דירה
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Banner
              type="mortgage"
              onClick={() => onBannerModalOpen('mortgage')}
            />
          </Grid>
          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            <ContactUsForm />
          </Grid>
        </Grid>
        <LoginModal onClose={hideModal} open={isOpen} />
        <BannerModal
          type={bannerType}
          onClose={hideBannerModal}
          open={isBannerModalOpen}
        />
      </WelcomeContainerMobile>
      <Footer onBuyerLogin={onBuyerLogin} onSellerLogin={onSellerLogin} />
    </>
  );
};

export default WelcomePage;
