import React from 'react';
import { render } from 'react-dom';
import dotenv from 'dotenv'
import App from './App/index';
import rollbar from './services/rollbar';

import segment from './utils/segment';
dotenv.config({ path: `../.env.${process.env.NODE_ENV}`, debug: false })

rollbar.initialize();
segment.initialize();


render(
    <App />,
    document.getElementById('root')
);

