import { createStyles, Theme } from '@material-ui/core/styles'

export const ModalTitle = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: 'Heebo',
    fontSize: '24px',
    color: '#000000'
  },
  text: {
    fontFamily: 'Heebo',
    fontSize: '24px',
    color: '#000000'
  }
})

export const CloseButton = (theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})
