import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import { useBreakpoint } from '../utils/breakpoints';
import LoginPage from '../LoginPage'
import Modal from '../Modal'

import * as S from './styles'

const useStyles = makeStyles(S.Styled)

export interface Props {
  leadId?: string
  onClose: () => void
  open: boolean
}

const LoginModal: React.FC<Props> = ({ onClose, open }) => {
  const isCompactLayout = useBreakpoint(['small', 'medium'])
  const classes = useStyles()

  return (
    <>
    {!open && (
      <Box visibility='hidden' height={0}>
        <LoginPage />
      </Box>
    )}
    <Modal open={open} maxWidth='md' fullWidth={isCompactLayout}>
      <Modal.Title onClose={onClose}>
        <Box textAlign='center'>
        כניסה או הירשמות
        </Box>
      </Modal.Title>
      <Modal.Content dividers className={classes.root}>
        {
          open && (
            <LoginPage />
          )
        }
      </Modal.Content>
    </Modal>
    </>
  )
}

export default LoginModal
