import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Grid, Container } from '@material-ui/core';
import { isEmpty } from 'lodash';
import useFetch, { CachePolicies } from 'use-http';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneIcon from '@material-ui/icons/Phone';

import Form from '../Form';
import ResultsMessage from '../ResultsMessage';
import useBannerUsFormValidation from '../hooks/useBannerFormValidation';
import LabelWrapper from '../LabelWrapper';
import Typography from '../Typography';
import { Styled } from '../welcomePage/desktop/styles';
import { BannerType } from '~/types/banner';

export interface InitProps {
  phone?: string;
  fullName: string;
  userComment: string;
}

export interface Props {
  onSubmit?: (data?: InitProps) => Promise<any>;
  type: BannerType;
}

const INIT_FORM: InitProps = {
  userComment: '',
  fullName: '',
};

const useStyles = makeStyles(Styled);

const BannerForm: React.FC<Props> = ({ onSubmit = () => {}, type }) => {
  const { post, response } = useFetch(
    `${process.env.HOST_URL}/api/feedbacks/contactUs`,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );
  const classes = useStyles();
  const [validateForm] = useBannerUsFormValidation();
  const [initData, setInitData] = useState(INIT_FORM);

  const handleOnSubmitForm = (values: InitProps | undefined) => {
    return post({
      name: values?.fullName,
      userComment: `Phone: ${values?.phone} , Source:${type} `,
    });
  };

  const handleOnSubmit = (data: InitProps) => {
    const vlidation = validateForm(data);

    if (!isEmpty(vlidation)) {
      return vlidation;
    }

    setInitData(data);
    onSubmit(data);

    return handleOnSubmitForm(data);
  };

  if (response.ok && response.status === 200) {
    return (
      <Container>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <ResultsMessage
              title="תודה שיצרת איתנו קשר, נחזור אליך בהקדם"
              icon={
                <ThumbUpIcon
                  fontSize="large"
                  style={{
                    height: 150,
                    width: 'auto',
                    marginTop: 40,
                    color: '#E087B1',
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Form
      initialValues={initData}
      onSubmit={handleOnSubmit}
      validate={validateForm}
      style={{ width: '100%', direction: 'rtl' }}
    >
      <Grid container spacing={4} direction="column" justifyContent="center">
        <Grid item container justifyContent="center">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography style={{ fontSize: '24px', color: '#000' }}>
                השאירו פרטים
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ fontSize: '22px', color: '#000' }}>
                ונחזור אליכם בהקדם
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container wrap="nowrap">
          <LabelWrapper
            label="שם מלא"
            icon={<PersonOutlineIcon fontSize="small" />}
            direction="column"
          >
            <Form.Input name="fullName" />
          </LabelWrapper>
        </Grid>
        <Grid item container wrap="nowrap">
          <LabelWrapper
            label="טלפון"
            icon={<PhoneIcon fontSize="small" />}
            direction="column"
          >
            <Form.Input name="phone" />
          </LabelWrapper>
        </Grid>

        <Grid justify="center" item container wrap="nowrap">
          <Form.SubmitButton
            color="primary"
            variant="contained"
            className={classes.textLinkForm}
          >
            <Typography color="inherit">שלח</Typography>
          </Form.SubmitButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export default BannerForm;
