import { createStyles } from '@material-ui/core/styles';

import { screens } from '../../utils/breakpoints';

const WELCOME_BIG_DOUBLE =
  'https://storage.googleapis.com/hoome-imgs/app-assets/images/frontend-images-v1/icons/welcome_big_double.png';
const WELCOME_BIG_SINGLE =
  'https://storage.googleapis.com/hoome-imgs/app-assets/images/welcome/big_image_white.png';

export const Styled = createStyles({
  titleHeader: {
    fontFamily: 'Heebo',
    textAlign: 'center',
    fontSize: '28px',
    color: '#000000',
    [screens('small', 'medium')]: {
      fontSize: '20px',
    },
  },
  topSection: {
    fontFamily: 'Heebo',
    margin: 0,
    marginBottom: '60px',
    height: '860px',
    backgroundColor: '#1F858A',
    color: '#FFFFFF',
    position: 'relative',
  },
  topSectionBgWrp: {
    position: 'absolute',
    height: '500px',
    width: '100%',
    bottom: 0,
  },
  topSectionBg: {
    // position: 'absolute',
    // height: '100%',
    // width: '100%',
    // bottom: 0,
    height: 250,
    minWidth: 900,
    backgroundPosition: 'center',
    backgroundImage: `url(${WELCOME_BIG_DOUBLE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    [screens('small', 'medium')]: {
      backgroundImage: `url(${WELCOME_BIG_SINGLE})`,
      height: 150,
      width: 350,
      minWidth: 'auto',
    },
  },
  appDemoWrp: {
    // background: 'linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 90%, rgba(0,0,0,0.1) 100%)'
  },
  topSectionLogin: {
    padding: '110px',
    // background: 'linear-gradient(to right, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.2) 90%, rgba(0,0,0,0.1) 100%)'
  },
  topSectionTextBold: {
    fontFamily: 'Heebo',
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '30px',
    color: '#006064',
    marginBottom: '20px',
  },
  topSectionText: {
    fontFamily: 'Heebo',
    textAlign: 'center',
    width: '600px',
    fontSize: '28px',
    color: '#000000',
    marginBottom: '40px',
    [screens('small', 'medium')]: {
      width: '90%',
      fontSize: '18px',
    },
  },
  topBar: {
    fontFamily: 'Heebo',
    textAlign: 'center',
    fontSize: '18px',
    cursor: 'pointer',
    color: '#000000',
    padding: '0 16px',
    // padding: '0 16px !important',
    '&:hover': {
      color: '#4BB5AF',
    },
    [screens('small', 'medium')]: {
      fontSize: '18px',
      marginRight: '50px',
    },
  },
  topBarFooter: {
    fontFamily: 'Heebo',
    textAlign: 'center',
    fontSize: '18px',
    cursor: 'pointer',
    color: '#fff',

    [screens('small', 'medium')]: {
      width: '90%',
      fontSize: '18px',
    },
  },
  textLink: {
    fontFamily: 'Heebo',
    textAlign: 'center',
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: '18px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '235px',
    height: '46.571px',
    padding: '15px 30px !important',
    background: '#4BB5AF',
    borderRadius: '5px',
    [screens('small', 'medium')]: {
      padding: '12px 15px !important',
      fontSize: '12px',
      width: '144px',
      height: '36px',
    },
    '&:hover': {
      background: '#014E52',
    },
  },
  textLinkForm: {
    fontFamily: 'Heebo',
    textAlign: 'center',
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: '18px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '144px',
    height: '36.571px',
    padding: '15px 30px !important',
    background: '#4BB5AF',
    borderRadius: '5px',
    [screens('small', 'medium')]: {
      padding: '12px 15px !important',
      fontSize: '12px',
      width: '144px',
      height: '36px',
    },
    '&:hover': {
      background: '#014E52',
    },
  },
  bottomSectionTitle: {
    fontFamily: 'Heebo',
    fontWeight: 500,
    textAlign: 'center',
    fontSize: '30px',
    color: '#000000',
    width: '100%',
    margin: '40px',
  },
  roundedBox: {
    fontFamily: 'Heebo',
    textAlign: 'center',
    background: '#4BB5AF',
    borderRadius: '10px',
    margin: '10px 6px 10px 6px',
    padding: '16px',
    fontSize: '17px',
    width: '80%',
  },
  roundedBoxLarge: {
    font: 'fontFamily',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    background: 'rgba(255, 255, 255, 0.9)',
    margin: 0,
    padding: '8px',
    minWidth: '100%',
    display: 'flex',
    fontSize: '22px',
    height: '160px',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
  },
  textGridItem: {
    textAlign: 'center',
    width: '70%',
  },
  linkAction: {
    fontFamily: 'Heebo',
    fontWeight: 'bold',
    fontSize: '17px',
    textDecorationLine: 'underline',
    color: '#4BB5AF',
    alignSelf: 'center',
    cursor: 'pointer',
  },
  titleSectionTitle: {
    color: '#000',
    lineHeight: '40px',
    // font-family: Rubik,
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 700,
    [screens('small', 'medium')]: {
      fontSize: '22px',
      textAlign: 'center',
      lineHeight: '25px',
    },
  },
  titleSectionSubtitle: {
    color: '#000',
    // fontFamily: Rubik,
    lineHeight: '30px',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    [screens('small', 'medium')]: {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '20px',
    },
  },
  titleSectionSubtitleGreen: {
    color: '#4BB5AF',
    textAlign: 'center',
    // fontFamily: Rubik,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    [screens('small', 'medium')]: {
      fontSize: '16px',
    },
  },
  DescriptionItemTitleGreen: {
    color: '#006064',
    textAlign: 'right',
    // fontFamily: Rubik,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    [screens('small', 'medium')]: {
      fontSize: '16px',
      textAlign: 'center',
    },
  },
  DescriptionItemTitle: {
    color: '#000',
    textAlign: 'right',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 700,
    [screens('small', 'medium')]: {
      fontSize: '16px',
      textAlign: 'center',
    },
  },
  DescriptionItemText: {
    color: '#000',
    textAlign: 'right',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 300,
    [screens('small', 'medium')]: {
      fontSize: '16px',
      textAlign: 'center',
    },
  },
  footer: {
    backgroundColor: '#006064',
    color: '#fff !important',
    width: '100%',
  },
  footerTitle: {
    fontSize: '26px',
  },
  footerSubTitle: {
    fontSize: '20px',
    cursor: 'pointer',
  },
  icon: {
    width: '34px',
    height: '34px',
    filter: 'invert(100%)',
  },
});
