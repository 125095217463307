import { InitProps } from '../../BannerForm';

type AssetFormValidation = {
  phone?: string;
  fullName?: string;
  bannerType?: string;
};

export default function useBannerFormValidation(): [
  (data: InitProps) => AssetFormValidation
] {
  const phoneRegex = /^\+?(972|0)-?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;

  const validateForm = (data: InitProps): AssetFormValidation => {
    let vlidationsError = {};

    if (!data.phone) {
      vlidationsError = {
        ...vlidationsError,
        פיםמק: 'שדה חובה',
      };
    }

    if (!data.fullName) {
      vlidationsError = {
        ...vlidationsError,
        fullName: 'שדה חובה',
      };
    }

    return vlidationsError;
  };

  return [validateForm];
}
