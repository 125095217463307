import React from 'react';
import WelcomeDesktop from './desktop'

const WelcomePage = () => {
  return (
    <WelcomeDesktop />
  )
}

export default WelcomePage;
