import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useBreakpoint } from '../utils/breakpoints';
import Modal from '../Modal';
import * as S from './styles';
import { BannerType } from '~/types/banner';
import BannerForm from '../BannerForm';

const useStyles = makeStyles(S.Styled);

export interface Props {
  leadId?: string;
  onClose: () => void;
  open: boolean;
  type?: BannerType;
}

const BannerModal: React.FC<Props> = ({ onClose, open, type }) => {
  const isCompactLayout = useBreakpoint(['small', 'medium']);
  const classes = useStyles();

  if (!open || !type) return null;

  return (
    <>
      <Modal open={open} maxWidth="md" fullWidth={isCompactLayout}>
        <Modal.Content dividers className={classes.root}>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="small" style={{ cursor: 'pointer' }} />
          </IconButton>
          <BannerForm type={type} />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default BannerModal;
