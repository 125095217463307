import React from 'react';

import segment from '../services/segment'
import 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { useAuthProviderAction } from '../AuthProvider/context'


import '../firebaseui-styling.global.css';


const LoginPage = () => {
  const { uiConfig, firebaseApp } = useAuthProviderAction()

  return (
  <div className="container">
    <StyledFirebaseAuth
      className="firebaseUi"
      uiConfig={uiConfig}
      firebaseAuth={firebaseApp.auth()}
    />
  </div>
  );
};

export default LoginPage;

