import { InitProps } from '../../ContactUsForm'

type AssetFormValidation = {
  email?: string
  fullName?: string
  textComment?: string
}

export default function useContactUsFormValidation(): [(data: InitProps) => AssetFormValidation] {
  const emailRegexp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i

  const validateForm = (data: InitProps): AssetFormValidation => {
    let vlidationsError = {}

    if (!emailRegexp.test(data.email)) {
      vlidationsError = {
        ...vlidationsError,
        email: 'שדה חובה'
      }
    }

    if (!data.fullName) {
      vlidationsError = {
        ...vlidationsError,
        fullName: 'שדה חובה'
      }
    }

    return vlidationsError
  }

  return [validateForm]
}
