import { createStyles } from '@material-ui/core/styles';

import { screens } from '../utils/breakpoints';

export const Styled = createStyles({
  root: {
    width: '300px',
    direction: 'rtl',
    [screens('small', 'medium')]: {
      width: 'auto',
    },
  },
});
