import React from 'react';
import WelcomePage from '../welcomePage';
import AuthProvider from '../AuthProvider'
import FontsLoader from '../utils/FontsLoader'
import segment from '../services/segment'
import { getRedirectToUrl } from '../utils'

import '../App.css';
import '../firebaseui-styling.global.css';

export const getUTMParams = () => {
  const appUrl = getRedirectToUrl('redirectUrl')
  const params: any = new URLSearchParams(appUrl)
  let utmParams: Record<string, string> = {};

  for (const [key, val] of params) {

    // check for prefix
    if (key.startsWith('utm_')) {

      // add to a new object array
      utmParams[key] = val;
    }
  }

  return utmParams
}


const App: React.FC = () => {
  const ua = navigator.userAgent || navigator.vendor;
  const isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;
  const isFacebook = (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1) ? true : false;
  if (isInstagram || isFacebook) {
    try {
      segment.track(`Welcome Page visit - from ${isInstagram ? 'Instagram' : 'Facebook'}`, {}, {
        campaign: getUTMParams()
      })
    } catch (e) {
      console.error(e)
    }

    if (/iPad|iPhone|iPod/.test(ua)) {
      window.location.href = `googlechrome://lookey.co.il${window.location.search}`

      return null
    }
    window.location.href = `intent:https://lookey.co.il${window.location.search}#Intent;end`

    return null
  }

  return (
    <AuthProvider>
      <div className="container">
        <FontsLoader />
        <WelcomePage/>
      </div>
    </AuthProvider>
  );
};

export default App;
