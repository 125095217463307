import React, { ReactNode, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'

import ModalTitle from './ModalTitle'
import ModalContent from './ModalContent'
import ModalActions from './ModalActions'

export type CompoundedComponentWithRef<
  P,
  R,
  // eslint-disable-next-line @typescript-eslint/ban-types
  S = {}
> = ForwardRefExoticComponent<P & RefAttributes<R>> & S

export interface Props extends DialogProps {
  children?: ReactNode
  onClose?: () => void
  open: boolean
  fullScreen?: boolean
}

export interface StaticProps {
  Content: typeof ModalContent
  Actions: typeof ModalActions
  Title: typeof ModalTitle
}

const Modal = forwardRef<HTMLElement, Props>(function Modal({
  children, onClose, open, fullScreen, ...rest
}, ref) {
  return (
    <Dialog ref={ref} onClose={onClose} aria-labelledby='modal-title' open={open} fullScreen={fullScreen} {...rest}>
      {children}
    </Dialog>
  )
}) as CompoundedComponentWithRef<Props, HTMLElement, StaticProps>

Modal.Title = ModalTitle
Modal.Content = ModalContent
Modal.Actions = ModalActions

export default Modal
